import styled from 'styled-components';
import Anchor from 'components/Anchor';

const TrustpilotListStyled = styled.div`
    margin-bottom: ${({ theme }) => `${theme.spacing[4]}`};

    /* 720 */
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        margin-bottom: ${({ theme }) => `${theme.spacing[8]}`};
    }
`;

const AnchorStyled = styled(Anchor).attrs({
    variant: Anchor.Variant.Button.SECONDARY,
})`
    max-width: 180px;
    margin: 0 auto;
    display: block;
`;

export { TrustpilotListStyled, AnchorStyled };
