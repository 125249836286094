import React from 'react';
import TrustpilotLoader from '../TrustpilotLoader';
import { AnchorStyled, TrustpilotListStyled } from './TrustpilotList.style';

const TrustpilotList = ({ t }) => (
    <TrustpilotLoader>
        {({ ref, className }) => (
            <>
                <TrustpilotListStyled>
                    <div
                        ref={ref}
                        className={className}
                        data-locale="en-GB"
                        data-template-id="539ad60defb9600b94d7df2c"
                        data-businessunit-id="516218d10000640005259226"
                        data-style-height="600px"
                        data-style-width="100%"
                        data-theme="light"
                        data-stars="4,5"
                    >
                        <a
                            href="https://uk.trustpilot.com/review/www.trustedhousesitters.com"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Trustpilot
                        </a>
                    </div>
                </TrustpilotListStyled>
                <AnchorStyled href="https://uk.trustpilot.com/review/www.trustedhousesitters.com">
                    {t('components_trustpilot_button')}
                </AnchorStyled>
            </>
        )}
    </TrustpilotLoader>
);

export default TrustpilotList;
